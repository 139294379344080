<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>日结/月结</span>
            </div>

            <!-- 收银日结-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToDailySettle"
                            class="font1"
                            :disabled="!hasDailySettlePrivilege"
                        >
                            收银日结
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateDailySettle"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可进行日结操作(以下指导为店长及以上权限的全店日结流程)
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewDailySettle"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核日结单</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToDailySettle"
                            :disabled="!hasCreateDailySettlePrivilege"
                            >①新建收银日结单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择交易部门-结算日期默认为当天(如果需要结其他日期请自行选择)-核对应收总额后在对应位置填写实收总额-保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToDailySettle"
                            :disabled="!hasReviewDailySettlePrivilege"
                        >
                            ②审核收银日结单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的收银日结单-点击审核-审核成功后月底可进行月结</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToDailySettle"
                            :disabled="!hasReviewDailySettlePrivilege"
                        >
                            ③收银日结数据不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现收银数量有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核-确定后可以点击删除
                            -之后重新新建收银日结单操作(当日可建立多笔日结，如日结有误或部分日结可以删除日结单后重新日结)
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const dailySettlePrivilegeFlag = 'menu.finance.dailySettle';
const openDailySettlePrivilegeFlag = `${dailySettlePrivilegeFlag}.open`;
const createDailySettlePrivilegeFlag = `${dailySettlePrivilegeFlag}.create`;
const reviewDailySettlePrivilegeFlag = `${dailySettlePrivilegeFlag}.review`;

export default {
    name: 'DailyMonthSettleGuide',
    data() {
        return {
            rolesForCreateDailySettle: [],
            rolesForReviewDailySettle: [],
        };
    },
    created() {
        //查询"能够创建日结单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createDailySettlePrivilegeFlag).then((rst) => {
            this.rolesForCreateDailySettle = rst;
        });
        //查询"能够审核日结单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewDailySettlePrivilegeFlag).then((rst) => {
            this.rolesForReviewDailySettle = rst;
        });
    },
    computed: {
        roleNamesForCreateDailySettle() {
            return this.rolesForCreateDailySettle.map((e) => e.name).join('，');
        },
        roleNamesForReviewDailySettle() {
            return this.rolesForReviewDailySettle.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToDailySettle() {
            Util.nameJump(this, dailySettlePrivilegeFlag);
        },
        hasDailySettlePrivilege() {
            return this.hasPrivilege(openDailySettlePrivilegeFlag);
        },
        hasCreateDailySettlePrivilege() {
            return this.hasPrivilege(createDailySettlePrivilegeFlag);
        },
        hasReviewDailySettlePrivilege() {
            return this.hasPrivilege(reviewDailySettlePrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
